/* --- TEXT --- */

.hots-text {
  font-family: 'MetronicForBlizzard';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  padding-left: 3px;
  color: #CCCCFF;
}

.no-left-pad {
  padding-left: 0px !important;
}

.hots-text-el {
  font-family: 'MetronicForBlizzard';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  padding-left: 3px;
  color: #CCCCFF;
}

.align-left {
  text-align: left;
}

.txt-large {
  font-size: 32px !important;
}

.txt-medium {
  font-size: 20px !important;
}

.hots-text-red {
  font-family: 'MetronicForBlizzard';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  padding-left: 3px;
  color: #d32f2f;
}

@media only screen and (min-width:993px){
  .container {
    width: 80%;
  }
}



/* --- BUTTONS --- */


.hots-button,
.hots-button:focus {
  background-color: transparent;
  border: 2px solid #CCCCFF;
  border-radius: 5px;
}

@media only screen and (min-width:993px) {
  .pad {
    margin: 10px;
  }
}

@media only screen and (max-width:992px) {
  .pad {
    margin: 5px;
  }
}  

.hots-button:hover {
  background: linear-gradient(to bottom, #490095, #030099);
  /*background: linear-gradient(to bottom, #5e00c5, #0500cc);*/
  /*background-color: #9b9bf0;*/
  border: 2px solid #9b9bf0;
}

/*
.hots-button:hover {
  background: linear-gradient(to bottom, #5e00c5, #0500cc);
  background-color: #9b9bf0;
  border: 0px solid #9b9bf0;
}

.hots-button,
.hots-button:focus {
  margin: 5px;
  background: linear-gradient(to bottom, #490095, #030099);
  border: 0px solid #CCCCFF;
  border-radius: 5px;
}
*/

/* --- DROPDOWNS --- */

.hots-dropdown {
  width: 130px auto;
  max-width: 250px;
  text-align-last: center;
}

.custom-select {
  background-color: transparent;
  border: 2px solid #CCCCFF;
}


/* --- GRID LAYOUT --- */

.row {
  margin: 40px auto;
}

.center-col {
  justify-content: center;
  display: flex;
}

.right {
  float: right;
}





/* --- MODALS AND FORMS --- */

.hots-modal {
  background-image: linear-gradient(to bottom, #002266, #220066);
  border: 2px solid #CCCCFF; 
  backdrop-filter: blur(5px);
  background-size: cover;
}

.hots-modal-team {
  background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(/resources/images/background-8.jpg);
  background-position:center top;
  border: 2px solid #CCCCFF; 
  backdrop-filter: blur(5px);
  background-size: cover;
}

.hots-modal-profile {
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(/resources/images/background-4.jpg);
  background-position:center top;
  border: 2px solid #CCCCFF; 
  backdrop-filter: blur(5px);
  background-size: cover;
}

.hots-modal-upload {
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/resources/images/background.jpg);
  background-position:bottom;
  border: 2px solid #CCCCFF; 
  backdrop-filter: blur(5px);
  background-size: cover;
}

input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #CCCCFF;
  box-shadow: none;
  color: #CCCCFF;
}

/* label focus color */
.input-field input[type=email]:not(.browser-default):focus:not([readonly]) + label,
.input-field input[type=password]:not(.browser-default):focus:not([readonly]) + label,
.input-field input[type=text]:not(.browser-default):focus:not([readonly]) + label {
  color: #CCCCFF;
}

.review-input input[type="email"]:not(.browser-default):focus:not([readonly]),
.review-input textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #333333 !important;
  box-shadow: none;
  color: #333333 !important;
}

.review-input input[type=email]:not(.browser-default):focus:not([readonly]) + label {
  color: #333333 !important;
}

input {
  color: #CCCCFF;
}

.review-input input {
  color: #333333 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #CCCCFF !important;
  -webkit-box-shadow: 0 0 0 100px #150567 inset !important;
  box-shadow: 0 0 0 100px #150567 inset !important;
}



/* --- TABS --- */

.nav-tab {
  cursor: pointer;
  background-color: transparent;
  border-left: 1px solid #CCCCFF;
  border-right: 1px solid #CCCCFF;
}

.active-tab {
  background: url(/resources/images/triangles-bottom.png);
  background-size: cover;
  background-position: bottom;
}

.text-shadow {
  text-shadow: 0 0 1rem #009cff;
  color: #ffffff;
}

.nav-tab:hover {
  background: url(/resources/images/stats-winner-glow.png);
  background-size: cover;
  background-position: center;
}

.span-fill {
  display: block;
}

.nav-tabs {
  padding: 0;
  margin: 0;
}

.hots-hline {
  border: 1px solid #CCCCFF;
}

.full {
  position:absolute; 
  width:100%;
  height:100%;
  top:0;
  left: 0;
  z-index: 1;
}

/*
.section-search input.autocomplete {
  color: #CCCCFF;
  background-color: #CCCCFF;
}

.input-field .prefix.active {
  color: #CCCCFF;
  background-color: #CCCCFF;
}
*/

.dropdown-content li>span:hover {
  background-color: #7986cb  ;
}

/*
.autocomplete-content {
  overflow:scroll;
  min-height: 1px;
  max-height: 190px;
  
}
*/

.dropdown-content li>a, .dropdown-content li>span {
  color: #000000; /* color of text not matched */
  background-color: #CCCCFF; /* color of background*/
}
.autocomplete-content li .highlight {
  color: #490095; /* color of matched text */
}

.material-icons {
  vertical-align: middle !important;
  padding-bottom: 3px;
}

.no-m-right {
  margin-right: 0px !important;
}

.collapsible-header {
  display: block;
  background: rgba(155, 155, 240, 0.25);
  backdrop-filter: blur(100px);
  border-bottom: 0px solid;
}

.collapsible-header:hover {
  background: rgba(155, 155, 240, 0.5);
}

.collapsible-body {
  background: rgba(155, 155, 240, 0.25);
  backdrop-filter: blur(100px);
  border-bottom: 0px solid;
}

i.icon-color {
  color: #CCCCFF;
}

.button-blue {
  background: #2676a669 !important;
}

.button-blue:hover {
  background: #2676a6BB !important;
}

.progress-background {
  border: 1px solid #CCCCFF;
  height: 15px;
  background: transparent
}

.progress-filler {
  background: linear-gradient(to bottom, #490095, #030099);
  /*background: linear-gradient(to bottom, #5e00c5, #0500cc);*/
}

.iconColor-light {
  color: #CCCCFF
}

.iconColor {
  color: #ffffff
}

.fb {
  background-color: #DD0004;
  border: 2px solid #000000;
  border-radius: 5px;
}

/* Bounce In */
.hvr-bounce-in {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  background-color: #DD0004;
}

.small-img {
  width: 75px;
  height: 75px;
}

.row-smallerMargin {
  margin: 20px auto;
}

/*
.hots-tooltip i {
  background-color: #CCCCFF;
  color: #000000;
}

.hots-tooltip:hover i {
  background: linear-gradient(to bottom, #490095, #030099);
  color: #ffffff;
}
*/
.hots-tooltip i {
  background: rgba(155, 155, 240, 0.2);
}

.hots-tooltip:hover i {
  background: rgba(155, 155, 240, 0.5);
}

.tooltip-border {
  border: 1px solid #9B9BF0;
  box-sizing: border-box; 
}

.no-background, .no-background:hover, .no-background:focus, .no-background:checked {
  background: transparent;
}

.tooltip-text {
  text-align: left;
}

.tooltip-container {
  max-width: 50px;
}

.span-fill2 {
  display: block;
  width: 100%;
}
.no-padding-button {
  padding: 0px !important;
}

.collapsible {
  border-radius: 5px;
  border-top: 0px solid; 
  border-right: 0px solid ;
  border-left: 0px solid;
}

.pad-mod-all {
  padding: 20px;
}
