.perfBox {
  -webkit-transition: padding-top 0.2s;
  transition: padding-top 0.2s;
  display: none;
  background: rgba(155, 155, 240, 0.15);
  border: 2.5px solid rgba(155, 155, 240, 0.7);
  backdrop-filter: blur(5px);
}

.perf-active {
  padding-top: 5px;
  display: inline-block;
}

@media only screen and (min-width:1200px) {
  .game-stats {
    font-size: 12px;
    color: #A3AACC;
  }
  .game-stats-win {
    font-size: 12px;
    text-shadow: 0 0 1rem #009cff;
    color: #ffffff;
  }
}

@media only screen and (min-width:993px) and (max-width:1199px) {
  .game-stats {
    font-size: 10px;
    color: #A3AACC;
  }
  .game-stats-win {
    font-size: 10px;
    text-shadow: 0 0 1rem #009cff;
    color: #ffffff;
  }
}

@media only screen and (max-width:992px) {
  .game-stats {
    font-size: 16px;
    color: #A3AACC;
  }
  .game-stats-win {
    font-size: 16px;
    text-shadow: 0 0 1rem #009cff;
    color: #ffffff;
  }
}


@media only screen and (min-width:993px) {
  .move-up {
    position: relative;
    top: -90px;
    bottom: -90px;
  }
}

.s5th {
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5th {
  width: 20%;
}

.v-center {
  top: 50%;
  transform: translateY(-20%);
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width:992px) {
  .pad-map-up {
    margin-top: 10px !important;
  }
}

.h2h-tab {
  border-left: 1px solid #CCCCFF;
  border-right: 1px solid #CCCCFF;
  margin-top: 5px;
}


.bottom-border {
  border-bottom: 1px solid #CCCCFF;
}


.row-smallestMargin-bottom {
  margin: 5px auto;
}

.margin-center {
  margin: auto !important;
}

.dot-win {
  height: 10px;
  width: 10px;
  background-color: #0387C5;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #000000;
}

.dot-loose {
  height: 10px;
  width: 10px;
  background-color: #D62752;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #000000;
}

.range {
  margin-bottom: 20px !important;
}

.rc-slider-mark {
  top: -15px !important;
}

.rc-slider-handle {
  background-image: url(/resources/images/handle_slider.png);
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
  margin-top: -8px !important;
  border: solid 0px !important;
  background-color: transparent !important;
}

.rc-slider {
  height: 18px !important;
}

.rc-slider-rail {
  height: 7px !important;
}

.rc-slider-track {
  background-color: #9b9bf0 !important;
  height: 7px !important;
}

.rc-slider-dot {
  bottom: -4px !important;
  width: 10px !important;
  height: 10px !important;
  
}

.rc-slider-dot-active {
  border-color: #9b9bf0 !important;
}


@media only screen and (max-width:992px) {
  .marginTop {
    margin-top: 15px !important;
  }
}


.blueish {
  background-color: #0387C555 !important;
}

.redish {
  background-color: #D6275255 !important
}