.hover-hero-pic {
  position: relative;
  display: inline-block;
}

.hover-hero-pic:hover > .overlay {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  left: 0;
  background: radial-gradient(#4d4b4b, #2A0052);
  background-repeat: no-repeat;
  opacity: 0.25;
  z-index: 100;
  display: block;
  border-radius: 30px;
}

.hover-hero-pic-2 {
  position: relative;
  display: inline-block;
}

.hover-hero-pic-2:hover > .overlay {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  left: 0;
  background: radial-gradient(#4d4b4b, #45166E);
  background-repeat: no-repeat;
  opacity: 0.25;
  z-index: 100;
  display: block;
  border-radius: 30px;
}
