.review-text {
  color: #333333;
  margin: 10px;
}

.review-text-center {
  color: #333333;
  text-align: center;
}

.rounded-modal {
  border-radius: 10px;
}

.review-text-button {
  color: #FFFFFF;
  background-color: transparent;
  
}

.review-button {
  background-color: #DD0004;
  margin: 5px;
}

.review-button:hover {
  background-color: #BB0000;
}

.review-background {
  background-color: transparent;
}

.review-modal {
  border: 2px solid #000000; 
  backdrop-filter: blur(5px);
  background-size: cover;
}

.review-input {
  margin: 10px;
}

.pad-top {
  padding-top: 15px !important;
}


.big-margin-top {
  margin-top: 100px !important;
}

.scroll-profile {
  overflow-y :scroll;
  height: 320px;
}

