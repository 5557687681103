.ul-drop {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ul-drop {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.Nav-dropdownBox {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#394e97), to(#493c97));
  background-image: linear-gradient(to bottom, #394e97, #493c97);
  -webkit-box-shadow: 0 0 6px rgba(20, 20, 102, 0.4), 0 0 24px rgba(20, 20, 102, 0.6);
  box-shadow: 0 0 6px rgba(20, 20, 102, 0.4), 0 0 24px rgba(20, 20, 102, 0.6);
  padding: 2px;
  overflow-y:auto;
  overflow-x:hidden;
  height: 100%;
  max-height: 300px;
}

.Nav-dropdownBox::-webkit-scrollbar {
  width: 0.3em;
}

.Nav-dropdownBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.Nav-dropdownBox::-webkit-scrollbar-thumb {
background-color: #CCCCFF;
/*outline: 3px solid red;*/
}

.drop-button {
  width: 100%;
}

.Nav-dropdownBoxInner {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#002266), to(#220066));
  background-image: linear-gradient(to bottom, #002266, #220066);
  -webkit-box-shadow: inset 0 0 10px rgba(153, 187, 255, 0.2);
  box-shadow: inset 0 0 10px rgba(153, 187, 255, 0.2);
}

.Nav-link.is-dropdown-active + .Nav-dropdown {
  padding-top: 5px;
  display: flex;

}

.Nav-dropdown {
  -webkit-transition: padding-top 0.2s;
  transition: padding-top 0.2s;
  position: absolute;
  top: 100%;
  display: none;
  z-index: 1;
}

.li-drop {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
  display: list-item;
  text-align: -webkit-match-parent;
}
.Nav-dropdownList {
  margin: 0;
  list-style: none;
  padding: 0;
  font-size: 14px;
  padding-bottom: 0.6em;
  padding-top: 0.6em;
}

.Nav-list {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
}
/*
.dropdown {
  line-height: 1;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  height: 100%;
  font-family: "MetronicforBlizzard", "Metronic", Helvetica, Arial, sans-serif;
  font-size: 0.83333rem;
  background: #0e001a;
  color: #ccf;
  font-size: 18px;
}
*/

.dropdown {
  white-space: nowrap;
  text-shadow: 0 1px 4px rgba(0, 0, 51, 0.4);
}

.dropdown {
  position: relative;
  display: inline-block;
}



.dropdown .Nav-item-text {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}

.Nav-item-text {
  color: #ccf;
  display: block;
  min-width: 220px;
  padding: 0.6em 1.5em;
  text-transform: uppercase;
  text-decoration: none;
}

.Nav-item-text:before {
  width: 2px;
  right: 100%;
  background: #00aaff;
}

.Nav-item-text:after, .Nav-item-text:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.li-drop:hover {
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#00aaff), to(transparent));
  background-image: linear-gradient(to right, #00aaff, transparent);
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  
}

.pad-left-drop {
  margin-left: 10px !important; 
}

/*.dropdown:hover .Nav-drowpdownList {display: block;}*/