.bar {
  margin-bottom: -0px !important;
}

.bar-title {
  padding-bottom: 5px;
}

.h40 {
  height: 40;
}

.bottom-align {
  vertical-align: bottom;
}