body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/resources/images/heroes-bg-1600.jpg) no-repeat;
  background-size: cover;
  background-position: bottom;
  background-color: black;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  min-height: 100%;
}
@font-face {
  font-family: 'MetronicForBlizzard';
  src: url('/resources/fonts/MetronicforBlizzard.woff') format('woff');
}
/* --- TEXT --- */

.hots-text {
  font-family: 'MetronicForBlizzard';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  padding-left: 3px;
  color: #CCCCFF;
}

.no-left-pad {
  padding-left: 0px !important;
}

.hots-text-el {
  font-family: 'MetronicForBlizzard';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  padding-left: 3px;
  color: #CCCCFF;
}

.align-left {
  text-align: left;
}

.txt-large {
  font-size: 32px !important;
}

.txt-medium {
  font-size: 20px !important;
}

.hots-text-red {
  font-family: 'MetronicForBlizzard';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  padding-left: 3px;
  color: #d32f2f;
}

@media only screen and (min-width:993px){
  .container {
    width: 80%;
  }
}



/* --- BUTTONS --- */


.hots-button,
.hots-button:focus {
  background-color: transparent;
  border: 2px solid #CCCCFF;
  border-radius: 5px;
}

@media only screen and (min-width:993px) {
  .pad {
    margin: 10px;
  }
}

@media only screen and (max-width:992px) {
  .pad {
    margin: 5px;
  }
}  

.hots-button:hover {
  background: linear-gradient(to bottom, #490095, #030099);
  /*background: linear-gradient(to bottom, #5e00c5, #0500cc);*/
  /*background-color: #9b9bf0;*/
  border: 2px solid #9b9bf0;
}

/*
.hots-button:hover {
  background: linear-gradient(to bottom, #5e00c5, #0500cc);
  background-color: #9b9bf0;
  border: 0px solid #9b9bf0;
}

.hots-button,
.hots-button:focus {
  margin: 5px;
  background: linear-gradient(to bottom, #490095, #030099);
  border: 0px solid #CCCCFF;
  border-radius: 5px;
}
*/

/* --- DROPDOWNS --- */

.hots-dropdown {
  width: 130px auto;
  max-width: 250px;
  text-align-last: center;
}

.custom-select {
  background-color: transparent;
  border: 2px solid #CCCCFF;
}


/* --- GRID LAYOUT --- */

.row {
  margin: 40px auto;
}

.center-col {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.right {
  float: right;
}





/* --- MODALS AND FORMS --- */

.hots-modal {
  background-image: linear-gradient(to bottom, #002266, #220066);
  border: 2px solid #CCCCFF; 
  -webkit-backdrop-filter: blur(5px); 
          backdrop-filter: blur(5px);
  background-size: cover;
}

.hots-modal-team {
  background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(/resources/images/background-8.jpg);
  background-position:center top;
  border: 2px solid #CCCCFF; 
  -webkit-backdrop-filter: blur(5px); 
          backdrop-filter: blur(5px);
  background-size: cover;
}

.hots-modal-profile {
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(/resources/images/background-4.jpg);
  background-position:center top;
  border: 2px solid #CCCCFF; 
  -webkit-backdrop-filter: blur(5px); 
          backdrop-filter: blur(5px);
  background-size: cover;
}

.hots-modal-upload {
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/resources/images/background.jpg);
  background-position:bottom;
  border: 2px solid #CCCCFF; 
  -webkit-backdrop-filter: blur(5px); 
          backdrop-filter: blur(5px);
  background-size: cover;
}

input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #CCCCFF;
  box-shadow: none;
  color: #CCCCFF;
}

/* label focus color */
.input-field input[type=email]:not(.browser-default):focus:not([readonly]) + label,
.input-field input[type=password]:not(.browser-default):focus:not([readonly]) + label,
.input-field input[type=text]:not(.browser-default):focus:not([readonly]) + label {
  color: #CCCCFF;
}

.review-input input[type="email"]:not(.browser-default):focus:not([readonly]),
.review-input textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #333333 !important;
  box-shadow: none;
  color: #333333 !important;
}

.review-input input[type=email]:not(.browser-default):focus:not([readonly]) + label {
  color: #333333 !important;
}

input {
  color: #CCCCFF;
}

.review-input input {
  color: #333333 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #CCCCFF !important;
  box-shadow: 0 0 0 100px #150567 inset !important;
}



/* --- TABS --- */

.nav-tab {
  cursor: pointer;
  background-color: transparent;
  border-left: 1px solid #CCCCFF;
  border-right: 1px solid #CCCCFF;
}

.active-tab {
  background: url(/resources/images/triangles-bottom.png);
  background-size: cover;
  background-position: bottom;
}

.text-shadow {
  text-shadow: 0 0 1rem #009cff;
  color: #ffffff;
}

.nav-tab:hover {
  background: url(/resources/images/stats-winner-glow.png);
  background-size: cover;
  background-position: center;
}

.span-fill {
  display: block;
}

.nav-tabs {
  padding: 0;
  margin: 0;
}

.hots-hline {
  border: 1px solid #CCCCFF;
}

.full {
  position:absolute; 
  width:100%;
  height:100%;
  top:0;
  left: 0;
  z-index: 1;
}

/*
.section-search input.autocomplete {
  color: #CCCCFF;
  background-color: #CCCCFF;
}

.input-field .prefix.active {
  color: #CCCCFF;
  background-color: #CCCCFF;
}
*/

.dropdown-content li>span:hover {
  background-color: #7986cb  ;
}

/*
.autocomplete-content {
  overflow:scroll;
  min-height: 1px;
  max-height: 190px;
  
}
*/

.dropdown-content li>a, .dropdown-content li>span {
  color: #000000; /* color of text not matched */
  background-color: #CCCCFF; /* color of background*/
}
.autocomplete-content li .highlight {
  color: #490095; /* color of matched text */
}

.material-icons {
  vertical-align: middle !important;
  padding-bottom: 3px;
}

.no-m-right {
  margin-right: 0px !important;
}

.collapsible-header {
  display: block;
  background: rgba(155, 155, 240, 0.25);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-bottom: 0px solid;
}

.collapsible-header:hover {
  background: rgba(155, 155, 240, 0.5);
}

.collapsible-body {
  background: rgba(155, 155, 240, 0.25);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-bottom: 0px solid;
}

i.icon-color {
  color: #CCCCFF;
}

.button-blue {
  background: #2676a669 !important;
}

.button-blue:hover {
  background: #2676a6BB !important;
}

.progress-background {
  border: 1px solid #CCCCFF;
  height: 15px;
  background: transparent
}

.progress-filler {
  background: linear-gradient(to bottom, #490095, #030099);
  /*background: linear-gradient(to bottom, #5e00c5, #0500cc);*/
}

.iconColor-light {
  color: #CCCCFF
}

.iconColor {
  color: #ffffff
}

.fb {
  background-color: #DD0004;
  border: 2px solid #000000;
  border-radius: 5px;
}

/* Bounce In */
.hvr-bounce-in {
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  background-color: #DD0004;
}

.small-img {
  width: 75px;
  height: 75px;
}

.row-smallerMargin {
  margin: 20px auto;
}

/*
.hots-tooltip i {
  background-color: #CCCCFF;
  color: #000000;
}

.hots-tooltip:hover i {
  background: linear-gradient(to bottom, #490095, #030099);
  color: #ffffff;
}
*/
.hots-tooltip i {
  background: rgba(155, 155, 240, 0.2);
}

.hots-tooltip:hover i {
  background: rgba(155, 155, 240, 0.5);
}

.tooltip-border {
  border: 1px solid #9B9BF0;
  box-sizing: border-box; 
}

.no-background, .no-background:hover, .no-background:focus, .no-background:checked {
  background: transparent;
}

.tooltip-text {
  text-align: left;
}

.tooltip-container {
  max-width: 50px;
}

.span-fill2 {
  display: block;
  width: 100%;
}
.no-padding-button {
  padding: 0px !important;
}

.collapsible {
  border-radius: 5px;
  border-top: 0px solid; 
  border-right: 0px solid ;
  border-left: 0px solid;
}

.pad-mod-all {
  padding: 20px;
}

.hover-hero-pic {
  position: relative;
  display: inline-block;
}

.hover-hero-pic:hover > .overlay {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  left: 0;
  background: radial-gradient(#4d4b4b, #2A0052);
  background-repeat: no-repeat;
  opacity: 0.25;
  z-index: 100;
  display: block;
  border-radius: 30px;
}

.hover-hero-pic-2 {
  position: relative;
  display: inline-block;
}

.hover-hero-pic-2:hover > .overlay {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  left: 0;
  background: radial-gradient(#4d4b4b, #45166E);
  background-repeat: no-repeat;
  opacity: 0.25;
  z-index: 100;
  display: block;
  border-radius: 30px;
}

.review-text {
  color: #333333;
  margin: 10px;
}

.review-text-center {
  color: #333333;
  text-align: center;
}

.rounded-modal {
  border-radius: 10px;
}

.review-text-button {
  color: #FFFFFF;
  background-color: transparent;
  
}

.review-button {
  background-color: #DD0004;
  margin: 5px;
}

.review-button:hover {
  background-color: #BB0000;
}

.review-background {
  background-color: transparent;
}

.review-modal {
  border: 2px solid #000000; 
  -webkit-backdrop-filter: blur(5px); 
          backdrop-filter: blur(5px);
  background-size: cover;
}

.review-input {
  margin: 10px;
}

.pad-top {
  padding-top: 15px !important;
}


.big-margin-top {
  margin-top: 100px !important;
}

.scroll-profile {
  overflow-y :scroll;
  height: 320px;
}


.bar {
  margin-bottom: -0px !important;
}

.bar-title {
  padding-bottom: 5px;
}

.h40 {
  height: 40;
}

.bottom-align {
  vertical-align: bottom;
}
.teambox {
  background: rgba(155, 155, 240, 0.15);
  border: 2.5px solid rgba(155, 155, 240, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  width: 100%;
}

.basic-stats-win {
  font-size: 14px;
  padding-left: 0px;
  margin-left: 0px;
  text-shadow: 0 0 1rem #009cff;
  color: #ffffff;
}

.basic-stats {
  font-size: 14px;
  padding-left: 0px;
  margin-left: 0px;
  color: #A3AACC;
}

.no-space {
  padding: 0px !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-space-at-all {
  padding: 0px !important;
  margin: 0px !important;
}

.hero-icon {
  height: 40px;
  line-height: 40px;
}

.hero-icon-small {
  width: 110%;
}

.hero-icon-big {
  width: 150%;
}

.pointable {
  cursor: pointer;
}

.small-pad {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pad-bottom {
  padding-bottom: 5px !important;
}

.best-player {
  vertical-align: middle;
}

.span-inline {
  display: inline-block;
}


.row-smallestMargin {
  margin: 10px auto;
}

.padding-top-barcomp {
  margin: 15px auto;
}

.perf-modal {
  background: rgba(38, 38, 61, 0.9);
  border: 2.5px solid rgba(155, 155, 240, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.row-marginLeft {
  margin-bottom: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-left: 0rem;
}


.center-col {
  height: 100%;
}

.margin-bottom {
  margin-bottom: 5px;
}
.perfBox {
  transition: padding-top 0.2s;
  display: none;
  background: rgba(155, 155, 240, 0.15);
  border: 2.5px solid rgba(155, 155, 240, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.perf-active {
  padding-top: 5px;
  display: inline-block;
}

@media only screen and (min-width:1200px) {
  .game-stats {
    font-size: 12px;
    color: #A3AACC;
  }
  .game-stats-win {
    font-size: 12px;
    text-shadow: 0 0 1rem #009cff;
    color: #ffffff;
  }
}

@media only screen and (min-width:993px) and (max-width:1199px) {
  .game-stats {
    font-size: 10px;
    color: #A3AACC;
  }
  .game-stats-win {
    font-size: 10px;
    text-shadow: 0 0 1rem #009cff;
    color: #ffffff;
  }
}

@media only screen and (max-width:992px) {
  .game-stats {
    font-size: 16px;
    color: #A3AACC;
  }
  .game-stats-win {
    font-size: 16px;
    text-shadow: 0 0 1rem #009cff;
    color: #ffffff;
  }
}


@media only screen and (min-width:993px) {
  .move-up {
    position: relative;
    top: -90px;
    bottom: -90px;
  }
}

.s5th {
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5th {
  width: 20%;
}

.v-center {
  top: 50%;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width:992px) {
  .pad-map-up {
    margin-top: 10px !important;
  }
}

.h2h-tab {
  border-left: 1px solid #CCCCFF;
  border-right: 1px solid #CCCCFF;
  margin-top: 5px;
}


.bottom-border {
  border-bottom: 1px solid #CCCCFF;
}


.row-smallestMargin-bottom {
  margin: 5px auto;
}

.margin-center {
  margin: auto !important;
}

.dot-win {
  height: 10px;
  width: 10px;
  background-color: #0387C5;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #000000;
}

.dot-loose {
  height: 10px;
  width: 10px;
  background-color: #D62752;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #000000;
}

.range {
  margin-bottom: 20px !important;
}

.rc-slider-mark {
  top: -15px !important;
}

.rc-slider-handle {
  background-image: url(/resources/images/handle_slider.png);
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
  margin-top: -8px !important;
  border: solid 0px !important;
  background-color: transparent !important;
}

.rc-slider {
  height: 18px !important;
}

.rc-slider-rail {
  height: 7px !important;
}

.rc-slider-track {
  background-color: #9b9bf0 !important;
  height: 7px !important;
}

.rc-slider-dot {
  bottom: -4px !important;
  width: 10px !important;
  height: 10px !important;
  
}

.rc-slider-dot-active {
  border-color: #9b9bf0 !important;
}


@media only screen and (max-width:992px) {
  .marginTop {
    margin-top: 15px !important;
  }
}


.blueish {
  background-color: #0387C555 !important;
}

.redish {
  background-color: #D6275255 !important
}
.ul-drop {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

.ul-drop {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

*, *:before, *:after {
  box-sizing: inherit;
}
.Nav-dropdownBox {
  background-image: linear-gradient(to bottom, #394e97, #493c97);
  box-shadow: 0 0 6px rgba(20, 20, 102, 0.4), 0 0 24px rgba(20, 20, 102, 0.6);
  padding: 2px;
  overflow-y:auto;
  overflow-x:hidden;
  height: 100%;
  max-height: 300px;
}

.Nav-dropdownBox::-webkit-scrollbar {
  width: 0.3em;
}

.Nav-dropdownBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.Nav-dropdownBox::-webkit-scrollbar-thumb {
background-color: #CCCCFF;
/*outline: 3px solid red;*/
}

.drop-button {
  width: 100%;
}

.Nav-dropdownBoxInner {
  background-image: linear-gradient(to bottom, #002266, #220066);
  box-shadow: inset 0 0 10px rgba(153, 187, 255, 0.2);
}

.Nav-link.is-dropdown-active + .Nav-dropdown {
  padding-top: 5px;
  display: -webkit-flex;
  display: flex;

}

.Nav-dropdown {
  transition: padding-top 0.2s;
  position: absolute;
  top: 100%;
  display: none;
  z-index: 1;
}

.li-drop {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
  display: list-item;
  text-align: -webkit-match-parent;
}
.Nav-dropdownList {
  margin: 0;
  list-style: none;
  padding: 0;
  font-size: 14px;
  padding-bottom: 0.6em;
  padding-top: 0.6em;
}

.Nav-list {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
}
/*
.dropdown {
  line-height: 1;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  height: 100%;
  font-family: "MetronicforBlizzard", "Metronic", Helvetica, Arial, sans-serif;
  font-size: 0.83333rem;
  background: #0e001a;
  color: #ccf;
  font-size: 18px;
}
*/

.dropdown {
  white-space: nowrap;
  text-shadow: 0 1px 4px rgba(0, 0, 51, 0.4);
}

.dropdown {
  position: relative;
  display: inline-block;
}



.dropdown .Nav-item-text {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}

.Nav-item-text {
  color: #ccf;
  display: block;
  min-width: 220px;
  padding: 0.6em 1.5em;
  text-transform: uppercase;
  text-decoration: none;
}

.Nav-item-text:before {
  width: 2px;
  right: 100%;
  background: #00aaff;
}

.Nav-item-text:after, .Nav-item-text:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.li-drop:hover {
  left: 0;
  right: 0;
  background-image: linear-gradient(to right, #00aaff, transparent);
  transition: opacity 0.2s;
  
}

.pad-left-drop {
  margin-left: 10px !important; 
}

/*.dropdown:hover .Nav-drowpdownList {display: block;}*/
