.teambox {
  background: rgba(155, 155, 240, 0.15);
  border: 2.5px solid rgba(155, 155, 240, 0.7);
  backdrop-filter: blur(5px);
  width: 100%;
}

.basic-stats-win {
  font-size: 14px;
  padding-left: 0px;
  margin-left: 0px;
  text-shadow: 0 0 1rem #009cff;
  color: #ffffff;
}

.basic-stats {
  font-size: 14px;
  padding-left: 0px;
  margin-left: 0px;
  color: #A3AACC;
}

.no-space {
  padding: 0px !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-space-at-all {
  padding: 0px !important;
  margin: 0px !important;
}

.hero-icon {
  height: 40px;
  line-height: 40px;
}

.hero-icon-small {
  width: 110%;
}

.hero-icon-big {
  width: 150%;
}

.pointable {
  cursor: pointer;
}

.small-pad {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pad-bottom {
  padding-bottom: 5px !important;
}

.best-player {
  vertical-align: middle;
}

.span-inline {
  display: inline-block;
}


.row-smallestMargin {
  margin: 10px auto;
}

.padding-top-barcomp {
  margin: 15px auto;
}

.perf-modal {
  background: rgba(38, 38, 61, 0.9);
  border: 2.5px solid rgba(155, 155, 240, 0.7);
  backdrop-filter: blur(5px);
}

.row-marginLeft {
  margin-bottom: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-left: 0rem;
}


.center-col {
  height: 100%;
}

.margin-bottom {
  margin-bottom: 5px;
}